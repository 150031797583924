define("itarp-business-application/engine", ["exports", "ember-load-initializers", "itarp-business-application/config/environment", "ember-resolver", "@ember/engine"], function (_exports, _emberLoadInitializers, _environment, _emberResolver, _engine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const {
    modulePrefix
  } = _environment.default;

  class ItarpBusinessApplicationEngine extends _engine.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "modulePrefix", modulePrefix);

      _defineProperty(this, "Resolver", _emberResolver.default);

      _defineProperty(this, "dependencies", {
        externalRoutes: ['expert-opportunity', 'expert-talentcloud', 'unauthorized', 'client-opportunities'],
        services: ['notifications', 'current-user', 'toast', 'layout', 'workflows', 'accountAccess', 'user-update-bus']
      });

      _defineProperty(this, "engines", {
        'itarp-opportunity-ui': {
          dependencies: {
            services: ['layout', 'workflows', 'toast'],
            externalRoutes: {
              expertise: 'profile.expertise',
              autofill: 'profile.autofill',
              'basic-info': 'profile.basic-info',
              'work-arrangement': 'profile.work-arrangement',
              'dynamic-route': 'dynamic-route'
            }
          }
        },
        'itarp-talentcloud-ui': {
          dependencies: {
            services: ['layout', 'workflows', 'toast'],
            externalRoutes: {
              expertise: 'profile.expertise',
              autofill: 'profile.autofill',
              'basic-info': 'profile.basic-info',
              'work-arrangement': 'profile.work-arrangement',
              'dynamic-route': 'dynamic-route'
            }
          }
        }
      });
    }

  }

  _exports.default = ItarpBusinessApplicationEngine;
  (0, _emberLoadInitializers.default)(ItarpBusinessApplicationEngine, modulePrefix);
});